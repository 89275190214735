import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, { title: _ctx.title }, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), null, {
        "filter-bar-dropdown": _withCtx(() => [
          _renderSlot(_ctx.$slots, "filter-bar-dropdown")
        ]),
        "filter-bar-slot": _withCtx(() => [
          _renderSlot(_ctx.$slots, "filter-bar-slot")
        ]),
        _: 2
      }, 1024))
    ]),
    _: 3
  }, 8, ["title"]))
}